@import '../../../../styles/variables.scss';

.auth-social {

    &__title {
        font-size: 24px;
        padding: 0;
    }

    &__btns-wrap {
        display: grid;
        grid-template-columns: repeat(auto-fill, 48px);
        grid-gap: 14px;
    }

    &__btn {
        display: inline-flex;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.8;
        }

        &--fb {
            background-color: #1976D2;
        }

        &--vk {
            background-color: #2787F5;
        }

        &--google {
            background-color: #DD4B39;
        }

        &--mail {
            background-color: #FC2C38;
        }

        &--ok {
            background-color: #EE8208;
        }

        &--ya {
            background-color: #FF0000;
        }
    }

    &__btn-icon {
        display: flex;

        svg {
            display: block;
        }
    }


    &__btn-text {
        display: none;
    }

}

.modal-login-open-id {
    width: auto;

    .modal-content {
        padding: 20px 32px 32px 32px;
    }

    .auth-social {
        &__btns-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            grid-gap: 0;
        }

        &__title {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 16px;
        }


        &__favorite-btns-wrap {
            display: grid;
            grid-gap: 16px;
            margin-right: 0;
        }

        &__btn {
            margin-left: 7px;
            margin-right: 7px;

            &:nth-child(-n+3) { //take only first three elements out of 6
                display: grid;
                grid-template-columns: 33px calc(100% - 33px);
                grid-gap: 20px;
                justify-content: flex-start;
                align-items: center;
                padding: 20px 22px;
                border-radius: 5px;
                font-size: 16px;
                color: #fff;
                flex-basis: 100%;
                flex-shrink: 1;
                margin-bottom: 15px;
                width: auto;
                height: auto;
                border-radius: 5px;
                margin-left: 0;
                margin-right: 7px;
            }

            &:nth-child(-n+3) .auth-social__btn-text {
                font-size: 16px;
                text-align: center;
                display: block;
            }
        }

        &__btn-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media #{$viewport-sm} {
    .modal-login-open-id {
        .auth-social {
            &__btns-wrap {
                max-width: 328px;
            }

            &__btn-text {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }
}
